<template>
    <div class="letter detail mw1260">
        <div class="d-header">
            <div class="title">
                우리 아이들의 꿈을 응원하는<br>
                <span>아빠미소멘토단</span>
            </div>
        </div>
        <div class="content-wrap">
            <div class="top clear">
                <div class="info">
                    <div class="job-category" :class="[`job_${boardData.bc_idx}`]"></div>
                    <div>
                        <div class="category">{{returnCategoryObj(boardData.bc_idx).mjc_job}}</div>
                        <div class="center">{{boardData.p_name}}</div>
                    </div>
                </div>
                <div class="date">{{returnDateFormat(boardData.created_at)}}</div>
            </div>
            <div class="content">
                <div class="title">
                    <div class="badge" :class="{'finish' : boardData.sbl_status === 2}">{{returnStatusText()}}</div>
                    {{boardData.wr_subject}}
                </div>
                <div class="photo">
                    <img :src="returnImageUrl()" class="img"  id="detailImg" @click="runRotate()">
                </div>
            </div>
            <div class="letter-info">
                <div class="i-top">
                    <div class="name">
                        <div>이름</div> {{boardData.sbl_name}}
                    </div>
                    <div class="job">
                        <div>꿈</div> {{boardData.sbl_job}}
                    </div>
                </div>
                <div class="write" v-html="replaceBr(boardData.wr_content)"></div>
            </div>
        </div>
        <div class="d-footer clear">
            <div class="f-info">
                <div class="like">추천 <board-like-component :boardData="boardData" :boardType="'letterLike'"></board-like-component></div>
                <div class="comment">댓글 <span>{{returnCount(boardData.CommentCount, 999)}}</span></div>
            </div>
            <button class="list_btn" @click="$router.back()">목록</button>
        </div>
        <div class="comment-wrap">
            <comment-component :boardType="'letterComment'" :boardKey="boardKey"></comment-component>
        </div>
    </div>
</template>

<script>
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex"
    import CommentComponent from "@/components/board/comment/CommentComponent";
    import BoardLikeComponent from "@/components/board/BoardLikeComponent";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "BoardDetailLetterLayout",
        mixins: [imageOption],
        components: {
            CommentComponent,
            BoardLikeComponent
        },
        props: {
            boardData: {
                type: Object,
                default: () => {
                    return {}
                },
            },
        },
        data() {
            return {
                categoryList: [],
                providerList: [],
                boardKey : Number(this.$route.params.wr_id),
            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.getCategory();
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            getCategory() {
                this.$api.$board.getJobCategory().then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.categoryList = res.List;
                    }
                })
            },
            returnCategoryObj(idx) {
                let cateIndex = this.categoryList.findIndex(cate => cate.mjc_idx === idx);
                if(cateIndex > -1) {
                    return this.categoryList[cateIndex];
                }
                return {};
            },
            returnCategoryClass(idx) {
                return jobCategoryClass[idx]
            },
            returnDateFormat(date) {
                if(!date) {
                    return ''
                }
                return this.$moment(date).format('YYYY.MM.DD HH:mm')
            },
            replaceBr(content) {
                if(util.isEmpty(content)) {
                    return '';
                }
                return content.replace(/(\n|\r\n)/g, '<br>');
            },
            returnImageUrl() {
                //return util.isEmpty(this.boardData.Files) ? '' : `${this.boardData.Files[0].org_url}?${this.setImageOptions(700, 700, 'png')}`;
                return util.isEmpty(this.boardData.Files) ? '' : `${this.boardData.Files[0].org_url}`;
            },
            runRotate() {
                let imgWrap = document.getElementById('detailImg');
                let transformStyle = imgWrap.style.transform.replace('rotate(', '')
                transformStyle = transformStyle.replace('deg)', '')
                transformStyle = transformStyle.replace(';', '')
                transformStyle = Number(transformStyle);
                imgWrap.style.transform = `rotate(${transformStyle + 90}deg)`;
            },
            returnCount(count, maxCount) {
                if(maxCount < count) {
                    return maxCount+'+';
                }
                return count
            },
            returnStatusText() {
                if(this.boardData.sbl_status === 1) {
                    return '진행중'
                }else if(this.boardData.sbl_status === 2) {
                    return '마감'
                }else{
                    return '취소'
                }
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>